.signupjourneycard {
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.33) 100%);
  border-radius: 21.1139px;
  width: 80%;
  padding: 20px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  gap: 50px;

  color: #ffffff;
}

.signupjourneycard__item {
  border-radius: 7.03797px;
  background-color: rgba(255, 255, 255, 0.3);
}

.signupjourneycard__item {
  display: flex;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.0759px;
  line-height: 21px;
  letter-spacing: 0.1em;

  flex-direction: column;
  color: #000000;
  padding: 20px;
  gap: 30px;
}

.signupjourneycard__carousel_div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.signupjourneycard__item_profile {
  display: flex;
  gap: 20px;
}

@media only screen and (max-width: 600px) {
  .signupjourneycard {
    display: none;
  }
}
