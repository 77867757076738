@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-style: normal;
}

a {
  text-decoration: none;
  color: black;
}

button:hover {
  cursor: pointer;
}

ul {
  list-style-position: inside;
}
