.profile2 {
  display: flex;
  padding: 40px;
  gap: 10px;
}
.profile2_left {
  width: 65vw;
}

.profile2_right {
  /* display: flex;
  flex-direction: column; */
  gap: 20px;
}

.sidemessage_stuck {
  position: sticky;
  top: 16px;
}

.MuiTabList-indicator {
  background-color: #cf166f;
}

.tab-container-requests {
  min-width: 400px;
}

.tabs-padding{
  padding: 24px !important;
}

@media only screen and (max-width: 1100px) {
  .profile2_right {
    display: none;
  }
  .profile2_left {
    width: 90vw;
    min-height: 490px;
  }
}

@media (max-width: 500px) {
  .tab-container-requests {
    min-width: 200px;
  }
  .profile2 {
    padding: 10px !important;
  }
  .tabs-padding{
    padding: 10px !important;
  }

}

.css-jpln7h-MuiTabs-scroller {
  overflow: scroll !important;
}

.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar {
  display: none;
}
