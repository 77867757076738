.popular-search-box h5 {
  font-size: 16px;
  margin-top: 5px;
}

.wedding-gallary-card-bg {
  background-color: white;
  border-radius: 20px 20px 20px 20px;
}

.wedding-gallary-card-bg img {
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;
}

.wedding-gallary-content {
  cursor: pointer;
  padding: 8px 10px;
  text-align: center;
}

.wedding-gallary-content h6 {
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
}
