.coming-soon-poster {
  background-image: url(../../Assets/banner-bg.png);
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
}

.coming-soon-title {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
}

.coming-soon-title h2 {
  letter-spacing: -1.7px;
  width: 100%;
  word-spacing: 15px;
  color: white !important;
  font-size: 70px;
  font-weight: 600;
  margin-bottom: 30px;
}

.coming-soon-title h4 {
  width: 100%;
  float: left;
  font-size: 40px;
  color: #b142a6;
  font-weight: bold;
  text-transform: uppercase;
}

.coming-soon-btn button{
    border: none;
    padding: 14px 38px;
    font-size: 16px;
    font-weight: 500;
}

.coming-soong-btn-2{
    background-color: #fdebff;
    color: #cd29a2;
}

.cooming-soon-footer{
    background-color: #2d2c2d;
    padding-top: 40px;
}

.coming-soon-footer-content-i{
    font-size: 26px;
}

.coming-soon-footer-content span{
    font-size: 20px;
}

.coming-soon-footer-content-i-1{
    font-size: 35px;
}

.hr-classes hr{
    max-width: 60%;
    color: white;
    margin: auto;
}

.coming-soon-copyrights p{
    font-size: 17px;
    color: #ffffff;
    text-align: center;

}