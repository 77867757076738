.title {
    margin-top: 60px;
}

.gender_state {
    width: 70%;
}

.personal_details {
    margin-top: 10px;
}

.bio {
    width: 70%;
    padding: 0px 20px;
}


/* 
.r_login {
  width: 72%;
  height: 90%;
} */

.placeholder-css::placeholder{
 color: black;  
}

.r_login__wrapepr1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin-top: -30px;
}

.r_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 78%;
    height: 57%;
    gap: 20px;
    margin-top: 20px;
    margin-left: 10%;
    margin-bottom: 30px;
    /* padding-top: 20px; */
    /* margin-bottom: 300px; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.dob,
.weight,
.city {
    width: 70%;
    /* padding: 0px 20px; */
}

.btn__next {
    color: white;
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 50px;
    margin: 0 auto;
    background: linear-gradient( 90.57deg, #f97096 16.59%, rgba(253, 7, 7, 0.8) 99.79%);
    margin-top: -10px;
    margin-bottom: 10px;
}


/* #form_container {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 250px;
} */


/* .login__wrapepr {
  height: 550px;
  margin-top: -60px;
}

.login {
  margin-top: 50px;
}
.login-padding {
  height: 670px;
}
 */

.title {
    margin-top: 60px;
}

.gender_state {
    width: 70%;
}

.personal_details {
    margin-top: 10px;
}

.bio {
    width: 70%;
    padding: 0px 20px;
}


/* 
.r_login {
  width: 72%;
  height: 90%;
} */

.r_login__wrapepr1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    margin-top: -30px;
}

.r_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 78%;
    height: 57%;
    gap: 20px;
    margin-top: 20px;
    margin-left: 10%;
    margin-bottom: 30px;
    /* padding-top: 20px; */
    /* margin-bottom: 300px; */
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}

.dob,
.weight,
.city {
    width: 70%;
    /* padding: 0px 20px; */
}

.btn__next {
    color: white;
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 50px;
    margin: 0 auto;
    background: linear-gradient( 90.57deg, #f97096 16.59%, rgba(253, 7, 7, 0.8) 99.79%);
    margin-top: -10px;
    margin-bottom: 10px;
}


/* #form_container {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 250px;
} */


/* .login__wrapepr {
  height: 550px;
  margin-top: -60px;
}

.login {
  margin-top: 50px;
}
.login-padding {
  height: 670px;
}
 */