.howdoesitworks {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: max-content;
}

.howdoesitworks > h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  text-align: center;
}

.howdoesitworks > p {
  width: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

@media only screen and (max-width: 1000px){
  .howdoesitworks > h2 {
    font-size: 30px;
    line-height: 24px;
    /* identical to box height */
  }

  .howdoesitworks > p {
    font-size: 18px;
    line-height: 25px;
    padding: 20px;
    width: 100%;
  }
}

