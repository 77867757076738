.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 91px;
  background: transparent;
  max-width: 100vw;
}

.h2__link {
  text-decoration: none;

  background: linear-gradient(
    91.6deg,
    #cf166f 4.86%,
    #3c0a53 34.48%,
    #fd0707 62.92%,
    rgba(220, 5, 255, 0.68) 101.52%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

h2.header__h2 {
  width: 207px;
  height: 75px;
  margin-left: 63px;
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 75px;
}

h2.header__h2 > span {
  font-weight: 400;
  margin-left: 5px;
}

.header__links > a {
  text-decoration: none;
  color: black;
  font-weight: 600;
  font-family: "poppins";
}
.header__links {
  display: flex;
  align-items: baseline;
  gap: 2rem;
  justify-content: end;
  margin-right: 15px;
}

p.AfterLogheader__button {
  color: white;
  height: 50px;
  width: auto;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.98deg, #5c0340 14.62%, #cf166f 117.57%);
  border-radius: 50px;
  cursor: pointer;
}

p.header__button {
  color: white;
  height: 38px;
  width: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.98deg, #5c0340 14.62%, #cf166f 117.57%);
  border-radius: 50px;
  cursor: pointer;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  display: flex;
  justify-content: center;
  width: 150px;
  margin-top: 2px;
}

.side_navbar {
  display: none;
  margin-right: 25px;
}

.offcanvas {
  width: 40% !important;
}

.header_navbar__h2 {
  text-decoration: none;
  background: linear-gradient(
    91.6deg,
    #cf166f 4.86%,
    #3c0a53 34.48%,
    #fd0707 62.92%,
    rgba(220, 5, 255, 0.68) 101.52%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 63px;
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
}

.side_nav_link li a {
  text-decoration: none;
  color: black;
  font-weight: 600;
  font-family: "poppins";
}

.side_nav_link li {
  margin-bottom: 10px !important;
}

.side_nav_body {
  padding: 20px 40px;
}

.Logout_btn_nav button {
  color: white;
  height: 43px;
  width: auto;
  margin-top: 30px;
  padding: 0px 30px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.98deg, #5c0340 14.62%, #cf166f 117.57%);
  border-radius: 50px;
  cursor: pointer;
}

.side_navbar button {
  border: none;
  background-color: transparent;
}

.side_navbar button img {
  height: 30px;
  margin-right: 15px;
}

.img_header_logo {
  width: 160px;
}

.logout-yes-no-1 {
  border: none;
  width: 90px;
  padding: 7px 10px;
  border-radius: 10px;
  color: white;
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%);
}
.logout-yes-no-2 {
  border: none;
  width: 90px;
  padding: 7px 10px;
  border-radius: 10px;
  color: white;
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%);
}

.header-logo-photo {
  width: 250px;
  cursor: pointer;
}

.header-menu-open {
  position: relative;
}

.header-menu-options {
  position: absolute;
  left: 0;
  margin-top: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 1111;
  background-color: white;
  border-radius: 5px;
}

.header-menu-options ul{
  list-style-type: none;
  padding: 10px 10px;
  width: 120px;
  margin-bottom: 0px;
}

.header-menu-options ul li{
  font-size: 14px;
  font-weight: 500;
  padding: 4px 0px;
  cursor: pointer;
}

.header-menu-options ul li:hover{
  background-color: rgb(193, 192, 192);
  padding: 4px 5px;
  border-radius: 5px;
}

@media only screen and (max-width: 1300px) {
  .hero {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (max-width: 1100px) {
  .header__links {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-right: 25px;
  }
}

@media only screen and (max-width: 800px) {
  .header__links {
    display: none;
  }
  .side_navbar {
    display: block;
  }
}

@media (max-width: 576px) {
  .offcanvas {
    width: 50% !important;
  }
  .header-logo-photo {
    width: 170px;
  }
}

@media (max-width: 400px) {
  .offcanvas {
    width: 100% !important;
  }

  h2.header__h2 {
    margin-left: 15px;
  }

  .side_navbar button img {
    height: 18px;
    margin-right: 0px;
  }
}
