.incomplete_box {
  width: 100%;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  min-height: 100px;
  border-radius: 20px;
  padding: 20px 20px 50px 30px;
}

.incomplete-profile-header-container {
  display: flex;
  grid-template-columns: 0.5fr 1fr;
}

.profile_circle {
  width: 100%;
  margin-top: 10px;
  height: 100px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.incomplete_add {
  box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 9%);
  border-radius: 15px;
  padding: 10px;
}

.incomplete_add h5 {
  font-size: 18px;
}

.incomplete_add p {
  color: lightgray;
}

.incomplete_add h6 {
  background-color: transparent;
  color: #cf166f;
  background: none;
  text-decoration: None;
}

@media (max-width: 500px) {
  .incomplete_box {
    border-radius: 6px;
    padding: 0px 10px 10px 10px;
  }
  .complete_profile_btn_2 h6 {
    font-size: 18px !important;
    margin-left: 10px;
  }
  .incomplete_add h5 {
    font-size: 14px;
  }
  .incomplete_add h6 {
    font-size: 12px;
  }
  .incomplete_add p {
    font-size: 10px;
  }
  .incomplete_add {
    box-shadow: 2px 2px 10px 0px rgb(0 0 0 / 9%);
    border-radius: 8px;
    padding: 8px 12px;
}
}
