.profile__invitations {
  display: flex;
  flex-direction: column;

  max-width: 60vw;
  min-width: 60vw;
  gap: 20px;
}

.profile__invitations_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile__invitations_heading > h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.profile__invitations_heading > h3 > span {
  color: #cf166f;
}
.profile__invitations_icon {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  padding: 10px;
  border-radius: 50%;
  border: 1px solid #cf166f;
}

.profile__invitations_icon:hover {
  cursor: pointer;
}

.profile__invitations_icon_wrapper {
  display: flex;
  gap: 20px;
}

.profile__invitations_wrapper {
  display: flex;
  gap: 20px;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 10px 0;
}
.profile__invitations_wrapper::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .profile__invitations {
    max-width: 90vw;
  }
}
