.messages_left {
  flex: 3;
  display: flex;
  flex-direction: column;
}

.online_users_chatbox {
  min-height: "60px";
  display: flex;
  overflow-x: auto;
  /* Hide the horizontal scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.online_users_chatbox::-webkit-scrollbar{
  display: none;
}

.messages_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-right: 20px;
  padding-bottom: 17px;
}

.messages_header_profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.messages_header_profile > img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.messages_message_panel {
  height: 300px;

  background: #ffffff;

  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 15px;
  overflow: scroll;
}

.messages_message_panel::-webkit-scrollbar {
  display: none;
}

.messages_right {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile__messages_wrapper {
  max-height: 500px;
  overflow: scroll;
  transition: 0.5s all;
}
.porfile__messages_back_icon {
  display: none;
}

.messages_input_form {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 10px 30px;
}

.messages_chat_input_wrapper {
  display: flex;
  padding: 5px 20px;
  align-items: center;
  gap: 10px;
  border: 1px solid rgb(251, 251, 251);
  border-radius: 5px;
  background: #fcfcfc;
  width: 100%;
}

.messages_chat_input_wrapper > input {
  border: none;
  background: #fcfcfc;
}

.messages_chat_input_wrapper > button {
  width: 100px;
  color: #f97096;
  height: 40px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: none;
  background: none;
}

.messages_chat_screen {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.online_box p {
  font-size: 12px;
}

.online_box img {
  border: 3px solid #cf166f;
  padding: 2px;
}

.message {
  display: flex;

  color: #000000;

  margin-bottom: 20px;
}

.message__message {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  text-align: justify;
}

.message__time {
  position: absolute;

  color: rgba(0, 0, 0, 0.5);

  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
}

.message_other_message {
  position: relative;
  display: flex;
}

.other_message_wrapper {
  max-width: 70%;
  background: linear-gradient(
    180deg,
    rgba(207, 22, 111, 0.25) 0%,
    rgba(253, 7, 7, 0.25) 100%
  );
  border-radius: 20px 0px 20px 20px;

  padding: 10px;

  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.other_message__time {
  bottom: -20px;
  left: 10px;
}

.message_my_message {
  position: relative;
  display: flex;

  justify-content: flex-end;
}

.my_message_wrapper {
  max-width: 70%;

  background: rgba(217, 217, 217, 0.5);
  border-radius: 0px 20px 20px 20px;

  padding: 20px 20px;

  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.my_message__time {
  bottom: -20px;
  right: 10px;
}

.yellow-text {
  display: flex;
  justify-content: end;
}

.yellow-text p {
  background: linear-gradient(
    180deg,
    rgba(207, 22, 111, 0.25) 0%,
    rgba(253, 7, 7, 0.25) 100%
  );
  text-align: right;
  max-width: 70%;
  border-radius: 20px 0px 20px 20px;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.red-text {
  display: flex;
  justify-content: start;
}

.red-text p {
  text-align: left;
  background: rgba(217, 217, 217, 0.5);
  border-radius: 0px 20px 20px 20px;
  padding: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
}

.upgrade_plan_buton {
  outline: none;
  border: none;
  background: linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%);
  border-radius: 20px;
  color: white;
  padding: 7px 15px;
}

.cancel_plan_buton {
  outline: none;
  border: none;
  background: linear-gradient(rgb(0, 0, 0) 0%, rgba(16, 15, 15, 0.5) 100%);
  border-radius: 20px;
  color: white;
  padding: 7px 15px;
}
