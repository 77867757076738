.banquet-hall-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.wedding-variety-menus {
  display: flex;
  gap: 50px;
  margin-bottom: 20px;
}

.wedding-variety-menus h5 {
  color: gray;
  padding-bottom: 8px;
  cursor: pointer;
}

.wedding-variety-menus-active {
  color: #dc05ff !important;
  border-bottom: 2px solid #dc05ff;
}

.wedding-card-title h5 {
  font-size: 17px;
  margin-top: 7px;
}

.banquet-hall-filters {
  background: linear-gradient(
    to right,
    rgba(253, 7, 7, 0.3) 20%,
    rgba(92, 3, 64, 0.3) 80%
  );
  background-color: white;
  border-radius: 30px;
}

.banquet-hall-filter-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 16px;
  gap: 20px;
}

.banquet-hall-filter-grid select {
  border-radius: 15px;
  background-color: white;
  padding-left: 5px;
  height: 40px;
  border-bottom: none;
  font-size: 13px;
  font-weight: 600;
}

.banquet-hall-review-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 100px;
}
