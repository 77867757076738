.login__wrapepr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}
.login__wrapepr_new {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0px 120px 0px;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 70%;
  gap: 20px;
  padding: 15px 0px 0px 0px;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.login_new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 600px;
  gap: 20px;
  padding: 15px 70px;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.login_new > div > a {
  color: gray;
}
.login > a {
  text-align: end;
  width: 70%;
  color: gray;
}

.login > .class_for_label {
  width: 70%;
}

.login_new > .class_for_label {
  width: 70%;
}

.login_new > button {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.login_new > button {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.login > button {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.login_new h2 {
  font-size: 30px;
}

.login h2 {
  font-size: 30px;
}

.login-padding {
  padding: 20px 0px;
}

.OTP_input input {
  text-align: center;
  width: 20%;
  margin-bottom: 20px;
}

.eye-password-relative {
  position: relative;
}

.eye-password {
  position: absolute;
  right: 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login_new {
    height: 350px;
    width: 500px;
    gap: 20px;
    padding: 15px 70px;
  }
  .login__wrapepr_new {
    padding: 60px 0px 120px 0px;
  }
  .login__wrapepr {
    display: flex;
    height: 472px;
  }
  .login {
    width: 100%;
    height: 300px;
    padding: 35px 0px 23px 0px;
  }
  .login > button {
    width: 135px;
    height: 37px;
  }
}

@media (max-width: 500px) {
  .login_new {
    height: 320px;
    width: 300px;
    gap: 20px;
    padding: 1px 30px;
  }
  .login__wrapepr_new {
    padding: 60px 0px 120px 0px;
  }
}
