.banquet-review-card-border {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 25px;
  }
  
  .banquet-review-card-border img {
    height: 100%;
  }
  
  .banquet-review-card-border h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 0px;
  }
  
  .banquet-review-card-border h6 {
      font-size: 12px;
      color: #000000;
      opacity: 0.6;
      line-height: 18px;
      margin-bottom: 0px;
  }
  
  .banquet-review-card-border p {
      font-size: 12px;
      color: #000000;
      opacity: 0.6;
      line-height: 18px;
  }
  
  .banquet-hall-amineties{
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
  }
  
  .banquet-hall-amineties span{
      border:  1.5px solid rgba(0, 0, 0, 0.3);
      padding: 4px;
      font-size: 8px;
      opacity: 0.6;
      border-radius: 5px;
  }
  
  .banquet-price{
      display: flex;
      gap: 20px;
      margin-top: 15px;
  }
  
  .banquet-price-first{
      display: flex;
      align-items: center;
      gap: 8px;
  }
  
  .banquet-price-first h5{
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
  }
  .banquet-red-icon{
      font-size: 6px;
      border: 2px solid #FF0000;
      color: #FF0000;
      padding: 3px;
  }
  .banquet-price-second{
      display: flex;
      align-items: center;
      gap: 8px;
  }
  
  .banquet-price-second h5{
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
  }
  .banquet-green-icon{
      font-size: 6px;
      border: 2px solid #008000;
      color: #008000;
      padding: 3px;
  }

  .banquet-review-by{
    border-top: 2px solid lightgray;
    display: flex;
    align-items: center;
    padding: 12px 0px;
  }

  .banquet-review-by p:last-child{
    text-decoration: underline;
    cursor: pointer;
  }
  
  