/* .upload_photographs {
  padding: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.upload_photographs > h2 {
  font-weight: 400;
  font-size: 50px;
  line-height: 75px;

  letter-spacing: 0.1em;
}

.upload__photo_wrapper {
  width: 100%;
  display: flex;
  gap: 30px;
}

.upload_photographs__upload_photo {
  width: 50%;
  padding: 50px 0;
  background: rgba(217, 217, 217, 0.01);
  border: 2px solid rgba(227, 83, 63, 0.33);
  border-radius: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.upload_photographs__upload_photo > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;

  letter-spacing: 0.1em;
}

button.signup__upload_button,
button.upload_photographs_save {
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.35) 100%);
  border-radius: 50px;
  width: 100%;
  height: 50px;
  border: none;
  color: white;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;

  letter-spacing: 0.1em;
}

.signup__upload_button__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup__upload_button__wrapper > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  letter-spacing: 0.1em;

  color: rgba(0, 0, 0, 0.5);
}

button.upload_photographs_save {
  width: 300px;
}

.upload_photographs_selected_photos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

img.upload_photographs_uploaded_image {
  height: 100px;
  width: 100px;

  filter: drop-shadow(1.91554px 1.91554px 4.78884px rgba(0, 0, 0, 0.1));
  border-radius: 9.57769px;
}

.upload_photographs_uploaded_image_wrapper {
  position: relative;

  width: 110px;
  height: 110px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_photographs_uploaded_image_close_icon {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px;
  border-radius: 50%;

  position: absolute;
  right: -15px;
  top: -15px;
}

.upload_photographs_uploaded_image_close_icon:hover {
  cursor: pointer;
} */

.upload_photographs {
  padding: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.photos {
  display: block !important;
  padding-bottom: 37px;
}

.upload_photographs > h2 {
  font-weight: 400;
  font-size: 50px;
  line-height: 75px;
  /* identical to box height */
  letter-spacing: 0.1em;
}



.upload_photographs__upload_photo {
  padding: 50px 0;
  background: rgba(217, 217, 217, 0.01);
  border: 2px solid rgba(227, 83, 63, 0.33);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.upload_photographs__upload_photo > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  letter-spacing: 0.1em;
  text-align: center;
}

button.signup__upload_button,
button.upload_photographs_save {
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.35) 100%);
  border-radius: 50px;
  width: 100%;
  height: 50px;
  border: none;
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  /* identical to box height */
  letter-spacing: 0.1em;
}

.signup__upload_button__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup__upload_button__wrapper > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: 0.1em;
  color: rgba(0, 0, 0, 0.5);
}

button.upload_photographs_save {
  width: 300px;
}

.upload_photographs_selected_photos {
  display: flex;
  flex-wrap: wrap;
}

img.upload_photographs_uploaded_image {
  height: 100px;
  width: 100px;
  filter: drop-shadow(1.91554px 1.91554px 4.78884px rgba(0, 0, 0, 0.1));
  border-radius: 9.57769px;
}

.upload_photographs_uploaded_image_wrapper {
  position: relative;
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_photographs_uploaded_image_close_icon {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  right: -15px;
  top: -15px;
}

.upload_photographs_uploaded_image_close_icon:hover {
  cursor: pointer;
}

@media (max-width: 992px) {
  .upload_photographs__upload_photo {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .upload_photographs > h2 {
    font-size: 40px !important;
    line-height: 10px;
    letter-spacing: 1px;
  }

  .upload-photo-margin{
    padding: 0px 10px;
  }

  .upload_photographs {
    padding: 20px 40px;
  }
  .signup__upload_button__wrapper > p {
    padding: 0px 20px;
}
}

@media (max-width: 576px) {
  .upload_photographs > h2 {
    font-size: 20px !important;
    line-height: 10px;
    letter-spacing: 1px;
  }

  .upload_photographs__upload_photo {
    width: 100%;
}

  .upload_photographs {
    padding: 20px 40px;
  }

  .upload_photographs {
    gap: 20px;
  }
  .upload_photographs__upload_photo > p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-align: center;
  }

  .upload_photographs__upload_photo {
    gap: 8px;
    padding: 10px 10px;
  }
  .signup__upload_button__wrapper > p {
    font-size: 8px;
  }

  button.upload_photographs_save {
    width: 100px;
  }

  button.upload_photographs_save {
    height: 34px;
    font-size: 18px
  }
  .upload_photographs_selected_photos {
    display: block;
  }
  .upload_photographs_uploaded_image_wrapper{
    width: 100%;
  }
}
