.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  width: 100%;
}

.payment-box {
  min-height: 600px;
  margin-bottom: 30px;
  width: 70%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding-top: 40px;
}

.payment-detail-data {
  padding-left: 170px;
}

.qr-code-image {
  display: flex;
  justify-content: start;
  align-items: center;
}

.qr-code-image img {
  height: 250px;
}

.payment-data span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.payment-data p {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
}

.payment-data input {
  border-bottom: 1px solid violet;
  width: 70%;
  display: block;
  padding: 2px 0px;
}

.payment-data button {
  background: #cf166f;
  border-radius: 7px;
  width: 103px;
  height: 40px;
  color: white;
  border: none;
}

@media (max-width: 1100px) {
  .payment-detail-data {
    padding-left: 120px;
  }
}

@media (max-width: 800px) {
  .payment-detail-data {
    padding-left: 70px;
  }
  .qr-code-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 700px) {
  .payment-detail-data {
    padding-left: 40px;
  }

  .qr-price-padding {
    padding-left: 48px;
    margin-top: 20px;
    padding-bottom: 30px;
  }
}

@media (max-width: 500px) {
  .payment-box {
    width: 100%;
  }
  .payment-detail-data {
    padding-left: 20px;
  }
  .payment-data button {
    width: 70%;
  }
}
