.social_in_box {
  height: 80%;
  width: 46%;
}

.social_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.social_background {
  height: 450px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 50px 30px 50px;
}

.health_checkbox input {
  height: 14px;
  width: 40px;
  border: none;
  border-bottom: 2px solid #f97096;
  font-weight: 400;
  font-size: 14.3414px;
  line-height: 0px;
  letter-spacing: 0.1em;
}

.health_submit {
  color: white;
  width: 180px;
  height: 44px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.social_media_links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.social_media_links input {
  width: 70%;
}

@media (max-width: 500px) {
  .social_in_box {
    height: 80%;
    width: 100%;
  }
  .social_background {
    padding: 30px 20px 30px 20px;
  }
  .health_checkbox input {
    height: 8px;
    width: 14px;
  }
}
