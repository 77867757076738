/* .invitationcard {
  max-width: 150px;
  min-width: 150px;
  max-height: 220px;
  min-height: 220px;

  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  background: #ffffff;
  box-shadow: 1.853971px 1.853971px 5.26986px rgba(0, 0, 0, 0.25);
  border-radius: 8.53971px;

  position: relative;

  margin-bottom: 15px;
}

.invitationcard_intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.invitationcard_bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p.proceed_further {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
}

button.invitationcard_upgrade_button {
  border-radius: 42.6986px;
  width: 80%;
  height: 30px;
  background: white;
  border: 1px solid rgba(207, 22, 111, 0.5);
  border-top: 2px solid rgba(207, 22, 111, 0.5);
}
button.invitationcard_upgrade_button:hover {
  cursor: pointer;
}

.invitationcard__buttons {
  display: flex;
  gap: 20px;
  position: absolute;
  bottom: -15px;
}

.invitationcard_button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  padding: 5px;
  background: #ffffff;
}

.invitationcard_button:hover {
  cursor: pointer;
}

.invitationcard_reject {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.invitationcard_accept {
  box-shadow: 1px 1px 5px rgba(42, 218, 14, 0.5);
} */

.invitationcard {
    max-width: 150px;
    min-width: 150px;
    max-height: 220px;
    min-height: 220px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: #ffffff;
    box-shadow: 1.853971px 1.853971px 5.26986px rgba(0, 0, 0, 0.25);
    border-radius: 8.53971px;
    position: relative;
    margin-bottom: 10px;
}

.invitationcard_intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.invitationcard_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

p.proceed_further {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: rgba(0, 0, 0, 0.5);
}

button.invitationcard_upgrade_button {
    border-radius: 42.6986px;
    width: 80%;
    height: 30px;
    background: white;
    border: 1px solid rgba(207, 22, 111, 0.5);
    border-top: 2px solid rgba(207, 22, 111, 0.5);
}

button.invitationcard_upgrade_button:hover {
    cursor: pointer;
}

.invitationcard__buttons {
    display: flex;
    gap: 20px;
    position: absolute;
    bottom: -15px;
}

.invitationcard_button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    padding: 5px;
    background: #ffffff;
}

.invitationcard_button:hover {
    cursor: pointer;
}

.invitationcard_reject {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.invitationcard_accept {
    box-shadow: 1px 1px 5px rgba(42, 218, 14, 0.5);
}