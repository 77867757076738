.signup {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 60px;
}

.signup__top {
  display: flex;
  gap: 40px;
}

.signup__left {
  flex: 1;
}

.signup__right {
  display: flex;
  flex-direction: column;
  gap: 100px;
  flex: 3;
}

.signup__right_bottom {
  display: flex;

  gap: 30px;
}

.signup__right_left {
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
}

.signup__education_details,
.signup__occupation_details,
.signup__diet_prefference,
.signup__home_town {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signup__home_town {
  gap: 20px;
}
.signup__basic_details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}
.signup__right_right {
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
}

input {
  height: 30px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #f97096;
  font-weight: 400;
  font-size: 14.3414px;
  line-height: 22px;
  letter-spacing: 0.1em;
}

input:focus {
  outline: none;
}

h3.signup__inpput_heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.07em;

  margin-bottom: 20px;
}

textarea.signup__bio {
  border: 1px solid #f97096;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 0px 50px 50px 50px;
  resize: vertical;
  padding-left: 30px;
  padding: 30px;
}

textarea.signup__bio:focus {
  outline: none;
}

textarea.signup__bio::-webkit-scrollbar {
  display: none;
}

.signup__bottom {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

input[type="select"] {
  background-color: #cf166f;
}

select {
  height: 30px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #f97096;
  font-weight: 400;
  font-size: 14.3414px;
  line-height: 22px;
  letter-spacing: 0.1em;
}

select:focus {
  outline: none;
}

option {
  border: 1px solid #f97096;
}

.signup > button {
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.signup_textarea{
  width: 100%;
}

@media (max-width: 1200px) {
  .signupjourneycard {
    width: 100%;
    padding: 20px;
    gap: 50px;
  }
}

@media only screen and (max-width: 1000px) {
  .signup__top {
    padding: 10px;
    gap: 40px;
}
  .signup__right {
    flex: 2;
  }

  .signup__upload_photographs {
    width: 60%;
  }

  .signup__upload_photographs > p {
    font-size: 15px;
    line-height: 28px;
  }

  button.signup__upload_button {
    font-size: 20px;
    line-height: 28px;
    height: 40px;
    width: 80%;
  }

  .signup__upload_button__wrapper > p {
    font-size: 12px;
    line-height: 17px;
  }

  .signup {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 15px;
}
}

@media only screen and (max-width: 800px) {
  .signup__right {
    flex: 1;
    gap: 50px;
  }
  .signup__basic_details {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .signup__right_bottom {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .signup__top {
    flex-direction: column-reverse;
  }

  h3.signup__inpput_heading {
    text-align: center;
  }
  .signup__upload_photographs {
    width: 80%;
  }
}
