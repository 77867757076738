.vendorinfo-grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.vendor-info-menu-box {
  border: 2px solid lightgray;
  border-radius: 15px;
  /* padding: 15px 15px; */
  height: 375px;
}

.vendor-info-menu-box h5:first-child {
  border-radius: 15px 15px 0px 0px;
  padding: 15px 15px;
}

.vendor-info-menu-box h5 {
  font-size: 17px;
  padding: 12px 15px;
  cursor: pointer;
}

.selected-vendor-info-menu {
  color: #cf166f;
  background-color: rgba(207, 22, 111, 0.1);
}
