.wedding-blog-grid-border {
  border-radius: 25px;
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  background-color: white;
}

.wedding-blog-grid-border img {
  border-radius: 25px;
}

.wedding-blog-grid-border h5 {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5%;
  margin-bottom: 20px;
}

.wedding-blog-grid-border h6 {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
}

.wedding-blog-grid-border p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19.6px;
  letter-spacing: 1.5%;
  opacity: 0.8;
  margin: 10px 0px 20px 0px;
}

.wedding-blog-owner-name {
  border-top: 1px solid rgba(51, 51, 51, 0.2);
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 8px;
  padding-top: 10px;
  margin-right: 15px;
}

.wedding-blog-owner-name p{
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
  letter-spacing: 0.5%;
  color: #333333;
  opacity: 0.6;
  margin-bottom: 0px;
}
