.my_login__wrapepr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
  margin-bottom: 200px;
}

.gender_state {
  width: 70%;
}

.my_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 950px;
  gap: 20px;
  margin-top: 180px;
  /* margin-bottom: 300px; */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.my_login > a {
  text-align: end;
  width: 70%;
  color: gray;
}

.my_login > button {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  margin-top: -10px;
  margin-bottom: 10px;
}

.family_wrapper {
  height: 608px;
}

.my_btn_next {
  padding: 10px;
}

@media (max-width: 1030px) {
  .family_wrapper {
    height: 608px;
  }

  .family_reg_height{
    height: 725px;
  }
}

@media (min-width: 768px) {
  .my_login {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .my_login {
    width: 45%;
    height: 130%;
  }
}
