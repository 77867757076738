.common-edit-form-col label {
  font-size: 14px;
  font-weight: 500;
}
.common-edit-form-col input,
.common-edit-form-col select,
.common-edit-form-col textarea {
  height: auto;
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid #f1a3b9;
  font-weight: 400;
  font-size: 14.3414px;
  line-height: 22px;
  letter-spacing: 0.1em;
}

.new-edit-submit-btn{
    margin-top: 20px;
    border-radius: 10px;
    background-color: #d3446d;
    color: white;
    border: none;
    padding: 8px 30px;
    font-weight: 600;
}

.common-edit-form-radion{
  display: flex;
}

.common-edit-form-radion input{
  width: auto;
}

.common-edit-form-radion label{
  font-weight: 400;
}

.edit-form-radion-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
