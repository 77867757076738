.wedding-packages-bg {
  background-image: url("../../../public/wedding-packages-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; /* Change 'auto' to 'cover' to ensure the background image covers the entire div */
  min-height: 100vh;
}

.wedding-packages-header {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  height: 100%;
  padding: 150px 0px;
}

.wedding-packages-header h2 {
  color: white;
  font-size: 44px;
  font-weight: 600;
  width: 50%;
  line-height: 66px;
  text-align: center;
}

.wedding-package-eader-icon {
  display: flex;
  color: white;
  gap: 80px;
}

.wedding-package-eader-icon img {
  width: 56px;
}

.wedding-package-eader-icon p {
  margin-bottom: 0px;
  font-size: 18px;
}

.wedding-select-package-header h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
}

.wedding-select-package-header p {
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  opacity: 0.4;
}

.wedding-package-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 75px;
}

.wedding-package-1 {
  border: 1px solid #ff6b85;
  border-radius: 30px;
  padding: 35px 30px;
  position: relative;
}

.wedding-package-2 {
  background-color: #eee1ff;
  border: none;
}

.wedding-package-1-header p {
  background-color: rgba(255, 107, 133, 0.1);
  border-radius: 15px;
  font-size: 16px;
  padding: 4px 0px;
  font-weight: 500;
  text-align: center;
}

.wedding-package-2-header {
  background-color: rgba(106, 57, 165, 0.1);
}

.wedding-package-1-price h5 {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #ff6b85;
}

.wedding-package-2-price h5 {
  color: #6a39a5;
}

.wedding-package-1-price p {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: gray;
}

.wedding-package-1-services-list {
  list-style: none;
  padding: 0px;
}

.wedding-package-1-services-list li {
  padding: 5px 0px;
}

.yes-mark {
  margin-right: 5px; /* Adjust spacing between mark and text */
  color: green; /* Color for "Yes" mark */
}

.no-mark {
  margin-right: 5px; /* Adjust spacing between mark and text */
  color: red; /* Color for "No" mark */
}

.wedding-package-gallary-slider {
  display: flex;
  overflow-x: hidden;
}

.wedding-packgae-product-gallary {
  margin-top: 10px;
  display: flex;
  overflow-x: scroll;
  gap: 30px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.wedding-packgae-product-gallary::-webkit-scrollbar {
  display: none; /* Hide scrollbar for webkit browsers */
}

.wedding-package-add-cart-btn button {
  position: absolute;
  bottom: -3%;
  left: 38%;
  font-size: 16px;
  background-color: #ff6b85;
  color: white;
  padding: 10px 20px;
  border-radius: 45px;
  box-shadow: none;
  border: none;
  font-weight: 600;
}

.wedding-package-add-cart-btn-1 button {
  background-color: #6a39a5;
}

.packages-latest-work-bg {
  background-color: rgba(220, 5, 255, 0.1);
  margin: 40px 0px;
}

.packages-latest-work-box {
  padding: 70px 0px 40px 0px;
}

.packages-latest-work-box h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 39px;
  margin-bottom: 30px;
}

.packages-latest-work-box-tabs-container {
  display: flex;
}

.packages-latest-work-box-tabs {
  display: flex;
  align-items: center;
}

.packages-latest-work-box-tabs-view-all p {
    text-decoration: underline;
    font-size: 14px;
}

.packages-latest-work-box-tabs-view-all {
  margin-left: auto;
}

.packages-latest-work-box-tab {
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 18px;
}

.packages-latest-work-box-tab-active {
  color: #dc05ff;
  border-bottom: 2px solid #dc05ff;
}

.tab-content {
  padding: 20px;
}

.packages-latest-work-box-first-tab-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
}

.packages-latest-work-box-first-tab-grid h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.packages-latest-work-box-first-tab-grid p {
  font-size: 14px;
}
.packages-latest-work-box-first-tab-grid span {
  font-size: 12px;
  color: gray;
  font-weight: 500;
}

.packages-latest-work-box-first-tab-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.packages-latest-work-box-first-tab-btn button {
  border: none;
  background: linear-gradient(92.98deg, #fd070780 14.62%, #5c034080 117.57%);
  color: white;
  border-radius: 30px;
  padding: 17px 55px;
  font-size: 20px;
  font-weight: 500;
}

.wedding-let-the-speak-heading h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
}

.wedding-let-the-speak-heading p {
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
  opacity: 0.4;
}

.packages-latest-work-box-first-speak-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

.packages-let-them-speak-boxes {
  border: 1px solid rgba(1, 1, 1, 0.3);
  border-radius: 25px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.packages-let-them-speak-boxes h4 {
  font-size: 22px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
}
.packages-let-them-speak-boxes h6 {
  font-size: 15px;
  font-weight: 500;
}

.packages-let-them-speak-boxes p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
