.p_login__wrapepr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
}

.p_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 130%;
  gap: 20px;
  margin-top: 36px;
  /* margin-bottom: 300px; */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.title_partnerpreference {
  margin-top: -10px;
}

.p_login > a {
  text-align: end;
  width: 70%;
  color: gray;
}

.p_login > button {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  margin-top: -10px;
  /* margin-bottom: 20px; */
}

.p_login > input {
  width: 70%;
}

.p_login > h2 {
  margin-top: 10px;
}

.age_scroll {
  margin-bottom: -10px;
}

.range-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 70%;
  gap: 10px;
}

@media (min-width: 768px) {
  .p_login {
    width: 60%;
    height: 130%;
  }
}

@media (min-width: 768px) {
  .p_login {
    height: 120%;
  }
}

.MuiSlider-root {
  color: "#f97096" !important;
}

@media (max-width: 500px) {
  .p_login {
    gap: 20px;
    margin-top: 10px;
    border-radius: 20px;
  }
  .gender_state {
    width: 80%;
}
}
