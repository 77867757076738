.profile2 {
  display: flex;
  padding: 40px;
  gap: 10px;
  margin-bottom: 100px;
}
.profile2_left {
  width: 69vw;
}

.profile2_right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.MuiTabList-indicator {
  background-color: #cf166f;
}

@media only screen and (max-width: 1000px) {
  .profile2_right {
    display: none;
  }
  .profile2_left {
    width: 90vw;
  }
  .profile2 {
    margin-bottom: 40px;
}
}
