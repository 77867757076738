.otp_text1 {
  font-size: 18px;
  text-align: center;
  margin-bottom: 5px;
}

.Phone_Number {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
}

.Otp_Div {
  display: flex;
  justify-content: center;
  padding: 40px 0;
}

.OtpBox {
  border: 1px solid black;
}

.Otp_Resend {
  background: linear-gradient(
    180deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  padding-left: 7px;
}
