.profile__messages {
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  gap: 30px;

  transition: 1s all;
}

.profile__messages_messages__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile__messages_messages__header_details > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.5);
}

.profile__messages_messages__header > h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #000000;
}
.profile__messages_messages__header > h3 > span {
  background: linear-gradient(92.98deg, #5c0340 14.62%, #cf166f 117.57%);
  border-radius: 50px;
  color: white;
  padding: 2px 10px;
}

button.messages__view_all {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  color: #a31447;
  background: none;
  border: none;
}

button.messages__view_all:hover {
  cursor: pointer;
}

.profile__messages_wrapper {
  max-height: 342px;
  min-height: 342px;
  
  overflow: hidden;
  transition: 0.5s all;
}

.profile__messages_wrapper::-webkit-scrollbar {
  display: none;
}

.porfile__messages_back_icon {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .porfile__messages_back_icon {
    display: block;
  }
  .profile__messages {
    box-shadow: none;
  }
  .profile__messages_wrapper {
    max-height: 100%;
  }
}


.profilemessagecard {
  display: flex;
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.2);
  padding: 14px 0px;
  gap: 20px;
}
.profilemessagecard > img {
  width: 50px;
  height: 50px;
}

.profilemessage__details {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: flex-start;
  gap: 3px;
}

p.profilemessage__name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  max-width: 60%;

  color: #000000;
}
p.profilemessage__message {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.5);
}
.ProfileMessage__time {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p.profilemessage_day {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.5);
  margin: auto;
}

p.profilemessage_unread_messages {
  background: linear-gradient(180deg, #cf166f 0%, rgba(227, 83, 63, 0.36) 100%);
  margin: auto;
  width: fit-content;
  padding: 1px 10px;
  border-radius: 100%;
  color: white;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}
