.latestprofiles {
  display: flex;
  height: 100%;
  margin-bottom: 50px;
  padding-bottom: 50px;
}

.profile__intro {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding-left: 10%;
}
.profile__intro > h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 45px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 1.5px;
}

.profile__intro > p {
  font-family: "Poppins";
  font-style: normal;
  width: 80%;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}

.profile__intro > button {
  font-family: "Poppins";
  font-style: normal;
  width: 50%;
  height: 65px;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: white;
  border: none;
  background: linear-gradient(
    92.45deg,
    rgba(253, 7, 7, 0.5) 3.6%,
    rgba(92, 3, 64, 0.5) 100%
  );
  border-radius: 50px;
}

.profile__imgs {
  display: flex;
  gap: 30px;
  width: 50%;
}

.column__1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.column__2 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  top: 50px;
}

@media only screen and (max-width: 1000px) {
  .latestprofiles {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  .profile__imgs {
    display: block;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    height: 50%;
    padding-top: 20px;
    padding-bottom: 70px;
    width: -webkit-fill-available;
  }

  .profile__intro {
    width: 50%;
    padding-left: 0%;
  }
  .profile__intro > h2 {
    font-size: 30px;
    line-height: 32px;
    /* identical to box height */

    text-align: center;
    margin-bottom: 20px;
  }

  .profile__intro > p {
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media only screen and (max-width: 800px) {
  .profile__intro {
    width: 100%;
    align-items: center;
  }
  .profile__intro > h2 {
    text-align: center;
  }

  .profile__intro > p {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
  }
  .column__1 {
    padding: 0px 40px;
  }

  .column__2 {
    padding: 0px 40px;
  }
}
