.health-sub-titles{
    width: 120px;
}
.health-sub-titles-2{
    width: 116px;
}

.edit-health-select{
    width: 147px;
}
.edit-health-select-2{
    width: 182px;
}

@media (max-width: 500px){
    .health-sub-titles , .health-sub-titles-2{
        width: 100%;
    }
    .edit-health-select-2 {
        width: 202px;
    }
}