.popular-search-box h5{
    font-size: 16px;
    margin-top: 5px;
}

.featured-vendor-bg{
    border-radius: 0px 0px 20px 20px;
}

.featured-vendor-content{
    padding: 20px 10px;
}

.featured-vendor-content h5{
    font-weight: 500;
    font-size: 15px;

}

.featured-vendor-content span{
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
}

.featured-vendor-content h6{
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
}

.featured-vendor-content p{
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #666666;
}

.featured-vendor-content i{
    color: gold;
}
