.venue-card-grid-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  padding: 10px 15px;
}

.venue-card-grid-border h5{
    font-size: 16px;
    font-weight: 500;
}

.venue-cities {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.venue-cities span {
  list-style: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 10px;
  /* margin-right: 5px; */
}

.see-all-venue-button {
  width: 100%;
  border: none;
  background: linear-gradient(
    to right,
    rgba(253, 7, 7, 0.5) 40%,
    rgba(92, 3, 64, 0.5) 70%
  );
  background-color: white;
  color: white;
  padding: 7px 0px;
  border-radius: 10px;
  font-size: 12px;
}
