.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.change-password-box {
  background: #fff;
  padding: 30px 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 500px;
  text-align: center;
}

.change-password-box h2 {
  margin-bottom: 40px;
}

.input-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.change-password-box input {
  flex: 1;
  padding: 10px;
}

.change-password-input-container {
  position: relative;
  margin: 20px 0px;
}

.change-password-icon {
  position: absolute;
  right: 0px;
  margin-right: 5px;
  cursor: pointer;
}

.change-password-box button {
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%);
  border-radius: 10px;
  color: white;
  height: 40px;
  text-align: center;
  width: 50%;
  margin-left: 10px;
  border: none;
}

.change-password-box button:hover {
  background-color: #0056b3;
}

.change-password-box p {
  margin-top: 10px;
  color: red;
}
