.forgotpassword {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forgot_password_box {
  height: 500px;
  width: 50%;
  display: grid;
  place-items: center;
}

.opt-btn {
  background: #cf166f;
  border-radius: 50px;
  width: 60%;
  height: 40px;
  color: white;
  border: none;
}

.close-modal-otp-btn {
  background: gray;
  border-radius: 50px;
  width: 100px;
  height: 40px;
  color: white;
  border: none;
}
.submit-modal-otp-btn {
  background: #cf166f;
  border-radius: 50px;
  width: 130px;
  height: 40px;
  color: white;
  border: none;
}

.back-white-shadow {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background-color: white;
  padding: 100px 80px 80px 80px;
}

.back-white-shadow input {
  width: 300px;
  padding: 0px 10px;
}

.new-password-relative{
  position: relative;
}
.new-password-relative .eye-password{
  position: absolute;
  right: 0;
}

.confirm-password-relative{
  position: relative;
}
.confirm-password-relative .eye-password{
  position: absolute;
  right: 0;
}

@media (max-width: 1100px) {
  .back-white-shadow {
    padding: 100px 80px 79px 80px;
  }
}
@media (max-width: 900px) {
  .back-white-shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background-color: white;
    border-radius: 20px;
    height: 232px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .back-white-shadow {
    padding: 10px 61px;
  }
}

@media (max-width: 500px) {
  .forgotpassword {
    display: block;
  }

  .back-white-shadow {
    padding: 0px 20px;
    width: 100%;
    height: 190px;
  }
  .forgot_password_box {
    height: 300px;
    width: 100%;
  }
  .back-white-shadow input {
    width: 200px;
    padding: 0px 10px;
  }
}
