.education-edit-container {
  padding: 20px 60px;
}

.m_login__wrapepr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58vh;
  margin-bottom: 200px;
}

.m_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  height: 120%;
  gap: 20px;
  margin-top: 200px;

  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 10px;
}

.m_login > a {
  text-align: end;
  width: 70%;
  color: gray;
}

.m_login > button {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  margin-top: -10px;
  margin-bottom: 10px;
}

.m_login > input {
  width: 70%;
}

.m_login > h2 {
  text-align: center;
}

.border_pink {
  border: 1px solid rgb(249, 112, 150);
  border-radius: 5px;
}

@media (min-width: 1024px) {
  .m_login {
    width: 50%;
    height: 572px;
    margin-top: 150px;
  }
}

@media (min-width: 768px) {
  .m_login {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .education-edit-container {
    padding: 20px 24px;
  }
}
