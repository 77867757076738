.vendor-packages-border {
  border: 2px solid lightgray;
  border-radius: 15px;
  padding: 25px;
}

.packages-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}

.first-package-vendor {
  border: 1px solid #ff6b85;
  border-radius: 15px;
  padding: 15px;
}

.vendor-package-title {
  background-color: #ff6b85;
  color: white;
  text-align: center;
  border-radius: 15px 15px 0px 0px;
  padding: 5px 0px;
}

.vendor-package-detail {
  color: #ff6b85;
  text-align: center;
  margin: 5px 0px;
  font-size: 18px;
  font-weight: 500;
}

.vendor-package-select-btn {
  display: flex;
  justify-content: center;
}

.vendor-package-select-btn button {
  background-color: #ff6b85;
  color: white;
  border: none;
  padding: 5px 25px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 600;
}
