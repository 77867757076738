.title {
  margin-top: 60px;
}

.gender_state {
  width: 82%;
}

.personal_details {
  margin-top: 10px;
}

.bio {
  width: 70%;
  padding: 0px 20px;
}

/* 
.r_login {
  width: 72%;
  height: 90%;
} */

.r_login__wrapepr1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-top: -30px;
}

.r_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 78%;
  height: 57%;
  gap: 20px;
  margin-top: 20px;
  margin-left: 10%;
  margin-bottom: 30px;
  /* padding-top: 20px; */
  /* margin-bottom: 300px; */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.dob,
.weight,
.city {
  width: 70%;
  /* padding: 0px 20px; */
}

.btn__next {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  margin-top: -10px;
  margin-bottom: 10px;
}

/* #form_container {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 250px;
} */

/* .login__wrapepr {
  height: 550px;
  margin-top: -60px;
}

.login {
  margin-top: 50px;
}
.login-padding {
  height: 670px;
}
 */

.title {
  margin-top: 60px;
}

.label_for_p {
  background-color: rgba(51, 51, 51, 0.05);
  border-radius: 8px;
  border-width: 0;
  color: #333333;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin-right: 15px;
  padding: 10px 12px;
  text-align: center;
  transition: all 200ms;
  vertical-align: baseline;
  white-space: nowrap;
}

.gender_state {
  width: 70%;
}

.personal_details {
  margin-top: 10px;
}

.bio {
  width: 70%;
  padding: 0px 20px;
}

/* 
.r_login {
  width: 72%;
  height: 90%;
} */

.r_login__wrapepr1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin-top: -30px;
}

.r_login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 78%;
  height: 57%;
  gap: 20px;
  margin-top: 20px;
  margin-left: 10%;
  margin-bottom: 30px;
  /* padding-top: 20px; */
  /* margin-bottom: 300px; */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.dob,
.weight,
.city {
  width: 70%;
  /* padding: 0px 20px; */
}

.btn__next {
  color: white;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  margin-top: -10px;
  margin-bottom: 10px;
}

.width_sm_hobby {
  width: 100px;
}

.editprofile-form-boxes {
  width: 70%;
}

.edit-dob{
  width: 70%;
}

.edit-age{
  width: 70%;
}

@media (max-width: 500px) {
  .width_sm_hobby {
    width: 120px;
  }

  .label_for_p {
    background-color: rgba(51, 51, 51, 0.05);
    border-radius: 8px;
    border-width: 0;
    color: #333333;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    list-style: none;
    margin-right: 15px;
    padding: 6px 10px;
    text-align: center;
    transition: all 200ms;
    vertical-align: baseline;
    white-space: nowrap;
  }
  .editpersonalprofile-container {
    padding: 0px;
  }
  .editprofile-form-boxes {
    width: 87%;
  }
  .edit-dob{
    width: 100%;
  }

  .edit-age{
    width: 100%;
  }
}

/* #form_container {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 250px;
} */

/* .login__wrapepr {
  height: 550px;
  margin-top: -60px;
}

.login {
  margin-top: 50px;
}
.login-padding {
  height: 670px;
}
 */
