.payment-grid {
  display: flex;
  justify-content: center;
}

.payment-method-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px;
}

.payment-method-box{
    height: 850px;
}
