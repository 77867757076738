.register_page{
    height: 600px !important;
}

.reg_wrapper{
    margin: 60px 0px 146px 0px;
}

.eye-password-confirm-relative{
    position: relative;
}

.eye-password-confirm{
    position: absolute;
    top: 4px;
    right: 10px;
}

.eye-password-confirm1-relative{
    position: relative;
}

.eye-password-confirm1{
    position: absolute;
    top: 4px;
    right: 10px;
}

@media only screen and (min-width: 425px) {
    .login {
        width: 78%;
        height: 79%;
        margin-top: 54px;
    }
}

@media only screen and (min-width: 1440px) {
    .login {
        width: 38%;
    }
}

@media (max-width : 576px){
    .register_page{
        width: 100% !important;
    }

    .reg_wrapper {
        padding: 0px 16px;
        margin: 20px 0px 146px 0px;
    }


}
