.whychooseus {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
}

.whychooseus > h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
}

.whychooseus > p {
  width: 50%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  text-justify: auto;
}

@media only screen and (max-width: 1000px) {
  .whychooseus > h2 {
    font-size: 30px;
    line-height: 24px;;
  }

  .whychooseus > p {
    font-size: 18px;
    line-height: 25px;
    padding: 20px;
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .whychooseus > h2 {
    font-size: 20px;
    line-height: 25px;
  }
}
