.catalog-card-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 25px;
}

.catalog-card-border img {
  height: 100%;
  border-radius: 25px 0px 0px 25px;
}

.catalog-card-border h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 0px;
}

.catalog-card-border h6 {
  font-size: 12px;
  color: #000000;
  opacity: 0.6;
  line-height: 18px;
  margin-bottom: 0px;
}

.catalog-card-border p {
  font-size: 12px;
  color: #000000;
  opacity: 0.6;
  line-height: 18px;
}

.banquet-hall-amineties {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.banquet-hall-amineties span {
  border: 1.5px solid rgba(0, 0, 0, 0.3);
  padding: 4px;
  font-size: 8px;
  opacity: 0.6;
  border-radius: 5px;
}

.banquet-price {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.banquet-price-first {
  display: flex;
  align-items: center;
  gap: 8px;
}

.banquet-price-first h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.banquet-red-icon {
  font-size: 6px;
  border: 2px solid #ff0000;
  color: #ff0000;
  padding: 3px;
}
.banquet-price-second {
  display: flex;
  align-items: center;
  gap: 8px;
}

.banquet-price-second h5 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
}
.banquet-green-icon {
  font-size: 6px;
  border: 2px solid #008000;
  color: #008000;
  padding: 3px;
}

.catalog-card-edit span {
  border: 1px solid rgb(163, 163, 163);
  padding: 1px 6px;
  border-radius: 8px;
  margin: 0px 4px;
}

.catalog-card-eye,
.catalog-card-edit-btn {
  color: gray;
  opacity: 0.7;
  font-size: 12px;
}

.modal-catalog-bg-color {
  background-color: rgba(0, 0, 0, 1);
  height: 100vh;
  width: 100vw;
}

.edit-catalog-width {
  width: 80% !important; /* Set the desired width */
  height: 80vh;
  margin: auto;
  background-color: white;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 10;
  left: 0px;
  right: 0px;
}

.catalog-modal-title {
  background-color: rgba(207, 22, 111, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

.catalog-modal-title h1 {
  font-size: 22px;
  color: #cf166f;
}
.catalog-modal-title button {
  border: none;
  background-color: #cf166f;
  color: white;
  padding: 1px 8px;
  border-radius: 50%;
}

.modal-catalog-form-1 {
  padding: 15px 20px;
}

.modal-catalog-form-1 h2 {
  font-size: 19px;
  margin-bottom: 2px;
}

.modal-catalog-form-1-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.modal-catalog-form-1-grid label {
  font-size: 12px;
  font-weight: 400;
}

.modal-catalog-form-1-grid select {
  border: 2px solid rgba(255, 141, 166, 0.5);
  border-radius: 10px;
  outline: none;
  padding: 17px 10px !important;
}

.text-input-ofpincode input {
  border: 2px solid rgba(255, 141, 166, 0.5);
  border-radius: 10px;
  outline: none;
  padding: 17px 10px !important;
}

.modal-catalog-form-1-grid input {
  border: 2px solid rgba(255, 141, 166, 0.5);
  border-radius: 10px;
  outline: none;
  padding: 17px 10px !important;
}

.modal-catalog-form-1-grid input:focus {
  outline: none;
}

.modal-catalog-form-1-grid input::placeholder {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.modal-catalog-form-1 button {
  border: none;
  color: white;
  border-radius: 10px;
  padding: 4px 20px;
  background: linear-gradient(
    180deg,
    #f97096 11.11%,
    rgba(207, 22, 111, 0.5) 100%
  );
}

.modal-catalog-form-grid{
    display: flex;
    gap: 10px;
}
