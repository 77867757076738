.bridal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}


.bridal-card-content {
  padding: 15px 20px 12px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 0px 0px 30px 30px;
}

.bridal-card-content p {
  font-weight: 500;
  margin-bottom: 4px;
  font-size: 14px;
}

.bridal-card-content h5 {
  font-weight: 600;
  margin-bottom: 4px;
}

.bridal-card-content span {
  color: gray;
  font-weight: 500;
  font-size: 14px;
}

.bridal-card-content h6 {
  text-align: end;
  font-weight: 600;
  color: #cf166f;
  font-size: 17px;
}
