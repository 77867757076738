.latestProfileCard {
  height: 50%;
  background-color: pink;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: auto;
  width: 15vw;
  min-height: 300px;
  max-height: 300px;
  border-radius: 20px;
  padding: 10px;
}
.latestProfileCard > img {
  height: 200px;
  width: 170px;
  border-radius: 5px;
  margin: 10px auto;
}

.latestProfileCard > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  padding: 0 20px;
}

.latestProfileCard > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1em;
  padding: 0 20px;
  color: #cf166f;
}

@media only screen and (max-width: 1000px) {
  .latestProfileCard {
    width: 25vw;
  }
  .latestProfileCard > img {
    margin: 10px auto;
  }
}

@media only screen and (max-width: 400px) {
  .latestProfileCard {
    width: 100%;
  }
  .latestProfileCard > img {
    margin: 10px auto;
  }
}
