.font-size-h1 h1 {
  font-size: 40px;
  letter-spacing: 1.2px;
}

.font-h1-policies h1 {
  font-size: 36px !important;
  color: black !important;
  background: black !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

@media (max-width: 800px) {
  .font-h1-policies h1 {
    font-size: 36px !important;
    color: black !important;
  }
}

@media (max-width: 600px) {
  .font-h1-policies h1 {
    font-size: 21px !important;
    color: black !important;
  }
}
