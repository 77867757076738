.vendor_forgot__wrapepr {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 540px;
  position: relative;
}

.back-left-arrow {
  position: absolute;
  top: 0px;
  left: 10%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 4px 15px;
  border-radius: 8px;
}

.vendor_login {
  width: 35%;
  height: 44%;
  padding: 15px 0px 0px 0px;
  border-radius: 20px;
  border: 1px solid #f97096;
}

.vendor_login > a {
  text-align: end;
  width: 70%;
  color: gray;
}

.vendor_login > .class_for_label {
  width: 70%;
}

.vendor-login-btn > button {
  color: white;
  width: 150px;
  height: 50px;
  border: none;
  font-weight: 600;
  font-size: 18px;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.vendor-login-btn > p {
  font-weight: 700;
  font-size: 15px;
}

.vendor-login-btn > p > span {
  color: #f97096;
  text-decoration: underline;
  cursor: pointer;
}

.vendor_login h2 {
  font-size: 30px;
}

.login-padding {
  padding: 20px 0px;
}

.OTP_input input {
  text-align: center;
  width: 20%;
  margin-bottom: 20px;
}

.eye-password-relative {
  position: relative;
}

.eye-password {
  position: absolute;
  right: 15%;
  cursor: pointer;
}

@media (max-width: 576px) {
  .vendor_login__wrapepr {
    display: flex;
    height: 472px;
  }
  .vendor_login {
    width: 100%;
    height: 300px;
    padding: 35px 0px 23px 0px;
  }
  .vendor_login > button {
    width: 135px;
    height: 37px;
  }
}
