.online_list_overflow {
  display: flex;
  overflow-x: auto;
  /* Hide the horizontal scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
  gap: 15px;
  user-select: none;
}

.online_list_overflow::-webkit-scrollbar {
  display: none;
}

.online_user_list {
  height: 141px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.online-user-profile-slider {
  height: 65px !important;
  width: 65px !important;
  border-radius: 100% !important;
  cursor: pointer !important;
}

.cursor-pointer-class{
  cursor: pointer;
}

@media (max-width: 768px) {
  .swiper-slide {
    width: calc((100% - 30px * 4) / 3); /* Adjust the width based on the number of slides you want per view */
  }
}

@media (max-width: 500px) {
  .swiper-slide {
    width: calc((100% - 15px * 4) / 3); /* Adjust the width based on the number of slides you want per view */
  }
}

@media (max-width: 500px) {
  .online-user-profile-slider {
    height: 50px !important;
    width: 50px !important;
    border-radius: 100% !important;
    cursor: pointer !important;
  }
  .swiper-slide{
    width: 14% !important;
  }
}
