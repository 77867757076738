.vendor-catalog-title {
  display: flex;
  justify-content: space-between;
}

.vendor-catalog-title h5{
    font-weight: 600;
    font-size: 24px;
}

.vendor-catalog-title button {
  border: none;
  color: white;
  border-radius: 10px;
  padding: 4px 20px;
  background: linear-gradient(
    180deg,
    #f97096 11.11%,
    rgba(207, 22, 111, 0.5) 100%
  );
}

.catalogcard-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}
