.bridal-makeup-intro-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.bridal-makeup-intro {
  padding: 0px 50px 0px 0px;
}

.bridal-makeup-intro h3 {
  font-size: 26px;
  font-weight: 600;
  display: inline;
  border-bottom: 3px solid #ff6b85;
}

.bridal-makeup-intro p {
  font-weight: 400;
  font-size: 19px;
  line-height: 30px;
  text-align: justify;
  color: #666666;
}

.bridal-makeup-intro-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.bridal-makeup-intro-list ul {
  padding: 0px;
}

.bridal-makeup-intro-list ul li {
  margin-bottom: 15px;
  color: #ff6b85;
}

.makeup-article {
  background-color: rgba(220, 5, 255, 0.05);
  padding: 80px 0px;
  margin-top: 70px;
}

.makeup-article-filter {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 35px;
}

.makeup-article-search {
  position: relative;
}

.makeup-article-search input {
  background-color: transparent;
  border: 1px solid #ff6b85;
  border-radius: 10px;
  padding: 8px 0px 8px 25px;
  height: 45px;
}

.makeup-article-search-icon {
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
  right: 2%;
  color: white;
  background: linear-gradient(92.98deg, #fd070780 14.62%, #5c034080 117.57%);
  padding: 10px 10px;
  font-size: 12px;
  border-radius: 6px;
}

.makeup-article-filter select {
  background: linear-gradient(92.98deg, #fd070780 14.62%, #5c034080 117.57%);
  border: none;
  height: 45px;
  border-radius: 10px;
  color: white;
  padding: 0px 20px;
}

.makeup-articles {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.makeup-article-card {
  background-color: white;
  border-radius: 30px;
  padding: 20px 20px;
}

.makeup-article-card h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 0px;
}

.makeup-article-card h4 {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 0px;
  font-weight: 600;
}

.makeup-article-card p {
  font-size: 16px;
  line-height: 27px;
  font-weight: 400;
}

.makeup-guides {
  margin: 100px 0px 50px 0px;
}

.makeup-guides-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.makeup-guides-header h3 {
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}

.makeup-guides-header p {
  text-decoration: underline;
}

.makeup-guides-photo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.makeup-guides-photo-grid img{
    height: 400px;
}
