.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: linear-gradient(
    180deg,
    rgba(255, 141, 166, 0.5) 0%,
    rgba(253, 7, 7, 0.5) 100%
  );
  opacity: 0.7;
  padding: 30px 50px 50px 50px;
  max-width: 100vw;
}

.footer__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer__links > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 8px;
  text-decoration: none;
  color: black;
}

h3.footer_h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.footer__contact_us {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__contact_us > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  color: black;
}

.footer__connect-us {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 15px;
  background-color: white;
  border-radius: 10px;
  width: 225px;
  height: 58px;
  margin-top: 20px;
}

img.footer__logo {
  height: 35px;
  width: 35px;
  overflow: hidden;
  border-radius: 20px;
}

.connect__linkes {
  display: flex;
  gap: 25px;
}

.foot_btn_btn {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 48px;
  border-radius: 10px;
  justify-content: flex-start;
}

.foot_bt span {
  font-size: 9px;
  line-height: 18px;
}

.foot_bt p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.foot_btn_btn img {
  height: 30px;
}

.all-right-reserved {
  color: black;
  font-weight: 600;
  margin-top: -130px;
  padding-top: 78px;
}

@media only screen and (max-width: 1300px) {
  .footer {
    max-width: 100vw;
  }
}

@media only screen and (max-width: 800px) {
  .footer {
    grid-template-columns: 1fr 1fr;
  }
  .footer__connect-us {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .connect__linkes {
    display: flex;
    gap: 12px;
  }
  .footer__links {
    justify-content: center;
    align-items: center;
  }

  .footer__contact_us {
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }

  .footer__connect-us {
    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr;
    padding: 30px 45px 74px 45px;
  }

  .all-right-reserved {
    margin-top: -130px;
    padding-top: 58px;
  }

  .download__button__content > p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 12px;
    letter-spacing: 0em;
  }
  .download__button__content > span {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
  }
  .footer__button {
    display: flex;
    align-items: center;
    padding-left: 15px;
    background-color: white;
    border-radius: 10px;
    width: 214px;
    height: 58px;
    margin-top: 20px;
    gap: 5px;
  }
}
