.health {
  height: 80%;
  width: 46%;
}

.health_box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.health_background {
  margin-top: 30px;
  min-height: 100px;
  background-color: white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 30px 50px 33px 50px;
}

.health_checkbox input {
  height: 14px;
  width: 40px;
  border: none;
  border-bottom: 2px solid #f97096;
  font-weight: 400;
  font-size: 14.3414px;
  line-height: 0px;
  letter-spacing: 0.1em;
}

.health_submit {
  color: white;
  width: 180px;
  height: 44px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.photo_border {
  border: 2px solid #f97096;
  border-radius: 10px;
  padding: 20px;
}

.photo_border img {
  width: 100%;
  height: 120px;
}

.blood-group-padding {
  width: 150px;
  margin-left: 20px;
}

.current-country-css p {
  font-size: 16px;
}

.current-country-css select {
  border: 1px solid rgb(249, 112, 150);
    width: 70%;
}

.family-status-css div h5{
  font-size: 16px;
}
.family-status-css div select{
  width: 70%;
}

.select-box-health-css{
  width: 50%;
}

@media (max-width: 500px) {
  .health {
    height: 80%;
    width: 90%;
  }
  .blood-group-padding {
    width: 100%;
    margin-left: 0px;
  }
  .health_background {
    padding: 30px 30px;
  }

  .current-country-css p {
    font-size: 12px;
  }
  .current-country-css select {
    border: 1px solid rgb(249, 112, 150);
    width: 73%;
    height: 25px;
    font-size: 11px;
  }
  .health_assets_checkbox label {
    font-size: 10px;
  }
  
  .family-status-css div h5{
    font-size: 10px;
  }
  .family-status-css div select{
    width: 73%;
    height: 25px;
    font-size: 11px;
  }
  .health_checkbox_css label{
    font-size: 9px;
  }
  .thalassemia-css h5{
    font-size: 10px;
  }
  .thalassemia-css div div div input{
    height: 10px;
  }
  .thalassemia-css div div div label{
    font-size: 10px;
  }
  .thalassemia-css div div{
    flex-wrap: wrap;
  }
  .select-box-health-css{
    width: 100%;
  }
  .settling-abroad-css div label{
    font-size: 13px;
  }
}
