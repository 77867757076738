.wedding-card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 30px;
  }

.wedding-variety-menus{
    display: flex;
    gap: 50px;
    margin-bottom: 20px;
}

.wedding-variety-menus h5{
    color: gray;
    padding-bottom: 8px;
    cursor: pointer;
}

.wedding-variety-menus-active{
    color: #DC05FF !important;
    border-bottom: 2px solid #DC05FF;
}

.wedding-card-title h5{
font-size: 17px;
margin-top: 7px;
}