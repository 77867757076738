.popular-search-box h5{
    font-size: 16px;
    margin-top: 5px;
}

.wedding-story-card-bg{
    background-color: rgba(220, 5, 255, 0.05);
    border-radius: 0px 0px 20px 20px;
}

.wedding-story-content{
    padding: 20px 10px;
}

.wedding-story-content span{
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
}

.wedding-story-content h6{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
}

.wedding-story-content p{
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #666666;
}
