.advancedsearch {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 60px;
}
.advancedsearch > h2 {
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */

  color: #000000;
}

.advancedsearch__elements_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  align-items: center;
}

.advancedsearch__element {
  display: flex;
  width: 60%;
}

.advancedsearch__element_lable {
  min-width: 30%;
  max-width: 30%;
}

.advancedsearch__element_selects_wrapepr {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
}

.advancedsearch__element_selects_wrapepr > select {
  border: none;
  background: rgba(217, 217, 217, 0.3);
  border-radius: 10px;
  width: 100%;
  height: 40px;
  padding: 7px 5px;
}

p.advancedsearch__element_lable {
  font-weight: 500;
  font-size: 16px;
  line-height: 38px;
  color: #cf166f;
}

.advancedsearch > button {
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 50px;
  margin: 0 auto;
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
}

.msl-vars {
  --input-width: 100% !important;
  height: 40px !important;
  border: none !important;
  background: rgba(217, 217, 217, 0.3) !important;
}

.msl {
  border: none !important;
}

.msl-option-active {
  background: #f7d6e0 !important;
  color: #f97096 !important;
}

.msl-option:focus,
.msl-options .msl-option:hover {
  background: #fbe5ec !important;
}

.msl-arrow-btn {
  height: 12px !important;
  width: 12px !important;
  font-weight: 900 !important;
}

.msl-arrow-icn > line {
  stroke-width: 4 !important;
}

.msl-options {
  overflow: scroll !important;
}

@media only screen and (max-width: 800px) {
  .advancedsearch__element {
    flex-direction: column;
    height: fit-content;
    width: 95%;
  }
}

@media (max-width: 576px) {
  .advancedsearch {
    gap: 10px;
    padding: 10px 25px;
  }

  .advancedsearch__elements_wrapper {
    gap: 20px;
  }

  p.advancedsearch__element_lable {
    margin-bottom: 5px;
  }

  .advancedsearch > h2 {
    font-size: 27px;
    line-height: 33px;
}
}
