.userprofile {
  display: flex;
  gap: 20px;
  padding: 60px;
}

.userprofile__left {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.userprofile__right {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.userprofile_profile_top {
  width: 100%;
  height: 160px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 55px;
  /* background-image: url("../../../public/my_profile_bg.png"); */
  background: linear-gradient(180deg, rgb(211 136 125 / 33%) 0%, #fcacd2 100%);
  background-position: center;
  background-size: cover;
}

.userprofile_profile_top > img {
  height: 110px;
  width: 110px;
  position: absolute;
  bottom: -25%;
  left: 39%;
}

.userprofile_profile_bottom_detail_wrapper {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 15px;
}

.userprofile_intro_bio {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0.978723px 0.978723px 4.89362px rgba(0, 0, 0, 0.15);
  border-radius: 9.78723px;
}

/* .jijiji{
  position: relative;
} */

.icon_position {
  position: absolute;
  bottom: -35px;
  right: 29%;
  background-color: violet;
  padding: 5px 7px;
  border-radius: 100%;
}

/* basic details  */

.userprofile_basic_details {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0.978723px 0.978723px 4.89362px rgba(0, 0, 0, 0.15);
  border-radius: 9.78723px;
}

.user-profile-by-id-hobbies-display {
  display: flex;
  align-items: center;
}

.userprofile_basic_details_content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.userprofile_family_details_content > img {
  height: 25px;
  width: 25px;
}

/* food preference  */

.userprofile_food_preference {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0.978723px 0.978723px 4.89362px rgba(0, 0, 0, 0.15);
  border-radius: 9.78723px;
}

.userprofile_food_preference_content_wrapper {
  display: flex;
  gap: 20px;
}

.userprofile_food_preference_content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.userprofile_food_preference_content > img {
  height: 45px;
  width: 45px;
}

/* education  */

.userprofile_education {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0.978723px 0.978723px 4.89362px rgba(0, 0, 0, 0.15);
  border-radius: 9.78723px;
}

.userprofile_education_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.userprofile_education_content {
  display: flex;
  gap: 10px;
}

/* family  */

.userprofile_family_details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0.978723px 0.978723px 4.89362px rgba(0, 0, 0, 0.15);
  border-radius: 9.78723px;
}

.userprofile_family_details_content_wrapper {
  display: grid;
  grid-template-columns: auto auto;
  gap: 20px;
}

.userprofile_family_details_content {
  display: flex;
  gap: 10px;
  align-items: center;
}

.userprofile_family_details_content > div > span {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.userprofile_family_details_content > div > p {
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
}

/* matches  */

.userprofile_matches {
  grid-row: 3/7;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 10px;
  box-shadow: 0.978723px 0.978723px 4.89362px rgba(0, 0, 0, 0.15);
  border-radius: 9.78723px;
}

img.userprofile_matches_user,
img.userprofile_matches_me {
  height: 50px;
  width: 50px;
}

.userprofile_matches_profile_images {
  display: flex;
  gap: 58px;
  position: relative;
  align-items: center;
  justify-content: center;
}

img.userprofile_matches_connection {
  position: absolute;
  z-index: -2;
}

.userprofile_matches_description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.userprofile_matches_description > span {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
}

.userprofile_matches_description > p {
  font-weight: 500;
}

.userprofile_matches_content_wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.userprofile_matches_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.userprofile_matches_content > img {
  width: 30px;
}

.userprofile_matches_content > p {
  max-width: 80px;
  min-width: 80px;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
}

/* section heading  */

.userprofile_section_header > h3 {
  font-weight: 600;
  font-size: 21.5319px;
  line-height: 32px;
}

.userprofile_section_header {
  display: flex;
  justify-content: space-between;
}

.userprofile_intro_name {
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;
}

.block_buton {
  position: absolute;
  top: 0;
  right: 0;
}

.block_buton button {
  outline: none;
  border: none;
  background: linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%);
  border-radius: 20px;
  color: white;
  padding: 7px 15px;
}

.block_buton button:last-child {
  background: linear-gradient(rgb(0, 0, 0) 0%, rgba(16, 15, 15, 0.5) 100%);
}

.userprofile_intro > h2 {
  font-weight: 600;
  font-size: 21.5319px;
  line-height: 22px;
}

.userprofile_intro > p {
  font-weight: 500;
  font-size: 15.5319px;
  line-height: 16px;
}

.package_detail_add {
  background: linear-gradient(180deg, #cf166f 0%, rgba(253, 7, 7, 0.5) 100%);
  border-radius: 10px;
  color: white;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.footer-btn-modal button {
  background: linear-gradient(
    90.57deg,
    #f97096 16.59%,
    rgba(253, 7, 7, 0.8) 99.79%
  );
  width: 70px;
  border: none !important;
  height: 35px;
  color: white;
  border-radius: 10px;
}

.footer-btn-modal button:first-child {
  background: linear-gradient(rgb(0, 0, 0) 0%, rgba(16, 15, 15, 0.5) 100%);
}

.img-img {
  position: relative;
}

.img_set {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.my_dropdown {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.img_img_img {
  position: absolute;
  top: 0;
  right: 0;
}

.my_dropdown button {
  display: block;
  margin-bottom: 10px;
  padding: 8px 12px;
  background: linear-gradient(92.98deg, #5c0340 14.62%, #cf166f 117.57%);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.my_dropdown button:last-child {
  margin-bottom: 0;
}

.my_dropdown button:focus {
  outline: none;
}

.edit_btn_profile {
  display: inline-block;
  padding: 4px 16px;
  background-color: #e0e0e0; /* Grey background color */
  color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.edit_btn_profile:hover {
  background-color: #bdbdbd; /* Darker grey background color on hover */
}

.edit_btn_profile:focus {
  outline: none;
}

.modal_btun button {
  background: linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%);
  border-radius: 20px;
  color: white;
  padding: 7px 25px;
}

.modal_btun button:last-child {
  background: linear-gradient(rgb(0, 0, 0) 0%, rgba(16, 15, 15, 0.5) 100%);
}

.modal-content-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 15px;
}

.block_confirm_button {
  border: none;
  padding: 10px;
  border-radius: 40px;
  background: linear-gradient(rgb(207, 22, 111) 0%, rgba(253, 7, 7, 0.5) 100%);
  color: white;
}

.block_confirm_button_2 {
  background: linear-gradient(rgb(0, 0, 0) 0%, rgba(16, 15, 15, 0.5) 100%);
  border: none;
  padding: 10px;
  border-radius: 40px;
  color: white;
}

.crown_img {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  top: 90px !important;
  left: 215px !important;
  transform: rotate(37deg) !important;
}

.whysoser {
  display: none;
}

.userdetail-by-id-crown {
  width: 25px !important;
  height: 25px !important;
  position: absolute !important;
  top: 80px !important;
  left: 210px !important;
  transform: rotate(35deg) !important;
}

@media (max-width: 1150px) {
  .userprofile_profile_top > img {
    height: 110px;
    width: 110px;
    position: absolute;
    bottom: -32%;
    left: 30%;
  }
  .crown_img {
    width: 25px !important;
    height: 25px !important;
    position: absolute !important;
    top: 90px !important;
    left: 151px !important;
    transform: rotate(37deg) !important;
  }
}
@media (max-width: 1024px) {
  .icon_position {
    bottom: -32px;
    right: 22%;
  }
}

@media (max-width: 768px) {
  .userprofile {
    display: block;
  }

  .userprofile_profile_top > img {
    bottom: -25%;
    left: 50%;
    transform: translateX(-50%);
  }

  .icon_position {
    bottom: -36px;
    right: 40%;
  }

  .crown_img {
    left: 59% !important;
  }

  .userprofile_food_preference_content_wrapper {
    flex-wrap: wrap;
  }
  .whysoserious {
    display: none;
  }

  .whysoser {
    display: block;
  }
}

@media (max-width: 600px) {
  .icon_position {
    bottom: -36px;
    right: 36%;
  }

  .userprofile {
    padding: 10px 35px;
  }

  .userprofile_intro_name {
    margin-top: 22px;
  }

  .user-profile-by-id-hobbies-display p {
    margin-bottom: 2px;
  }
  .user-profile-by-id-hobbies-display h6 {
    width: 77%;
  }
  .userdetail-by-id-crown {
    width: 25px !important;
    height: 25px !important;
    position: absolute !important;
    top: 80px !important;
    left: 166px !important;
    transform: rotate(35deg) !important;
  }
}

@media (max-width: 400px) {
  .icon_position {
    bottom: -36px;
    right: 31%;
  }

  .userprofile {
    padding: 10px 16px;
  }

  .userprofile_family_details_content_wrapper {
    gap: 0px;
  }

  /* .edit_btn_profile {
    padding: 0px 16px;
  } */
  .edit_btn_profile {
    width: 40px;
    font-size: 10px;
    padding: 0px;
    height: 25px;
  }
}
