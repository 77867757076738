.profile__details {
  display: flex;
  align-items: flex-start;
  min-width: 100%;
  gap: 30px;
}

.profile__details > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 330px;
}

.profile__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.profile__card_user {
  display: flex;
  gap: 30px;
}

.profile__card_user > img {
  height: 50px;
  width: 50px;
}

.profile__card_name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile__card_status {
  display: flex;
  gap: 30px;
}

button.profile__get_premium {
  background: #cf166f;
  border-radius: 50px;
  width: 80%;
  height: 40px;
}

.profile__counts {
  display: flex;
  gap: 10px;
}

.profile__counts_card {
  height: 80px;
  width: 80px;
  background: linear-gradient(180deg, rgba(227, 83, 63, 0.33) 0%, #cf166f 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 7px;
  padding: 10px;
}

.profile__details > img {
  width: 480px;
  height: 340px;
}

@media only screen and (max-width: 800px) {
  .profile__details {
    flex-direction: column;
    align-items: center;
  }
  .profile__details > div {
    width: 80%;
  }
  .profile__counts {
    align-items: center;
    justify-content: center;
  }
  .profile__details > img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .profile__details > div {
    width: 90%;
  }
}

.profile__details {
  display: flex;
  align-items: flex-start;
  min-width: 100%;
  gap: 30px;
}

.profile__details > div {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 350px;
}

.profile__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 30px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.profile__card_user {
  display: flex;
  gap: 30px;
}

.profile__card_user > img {
  height: 50px;
  width: 50px;
}

.profile__card_name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile__card_status {
  display: flex;
  gap: 30px;
}

.user_data {
  margin-bottom: -25px;
}

button.profile__get_premium {
  background: #cf166f;
  border-radius: 50px;
  width: 80%;
  height: 40px;
}

.btn_get_premium,
.btn_get_requests {
  text-decoration: none;
  color: white;
  border: none;
}

.profile__counts {
  display: flex;
  gap: 10px;
}

.profile__counts_card {
  height: 105px;
  width: 100px;
  background: linear-gradient(180deg, rgb(211 136 125 / 33%) 0%, #fcacd2 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
  justify-content: center;
  gap: 7px;
  padding: 10px;
}

.profile__counts_card:hover {
  background: linear-gradient(180deg, rgba(227, 83, 63, 0.33) 0%, #cf166f 100%);
  color: white;
}

.profile__counts_card1 > h3 {
  margin-top: 20px;
  margin-bottom: -5px;
}

.profile__counts_card2 > h3 {
  margin-top: 20px;
  margin-bottom: -5px;
}

.profile__details > img {
  /* margin-top: 10px; */
  width: 600px;
  height: 340px;
}

.profile_photo_first_profile {
  min-width: 60px;
  min-height: 60px;
  border-radius: 50%;
  max-height: 60px;
  max-width: 60px;
}

.profile_circle {
  width: 100%;
  margin-top: 10px;
  height: 100px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
.profile_circle_main {
  width: 542px;
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  margin-top: 10px;
  height: 110px;
  border-radius: 15px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.complete_profile_btn h6 {
  color: #cf166f;
  margin-left: 20px;
}

.complete_profile_btn h5 {
  margin-left: 20px;
  font-size: 18px;
}

.slide_media_width {
  width: 500px;
}

.media_for_height_slider {
  height: 270px;
}

.backend-image-slider {
  height: 260px;
  width: 100%;
  border-radius: 10px;
}

.backend-image-slider-container-main {
  width: 542px !important;
}

.backend-image-slider-container {
  width: 542px;
}

.slider-main-width {
  width: 542px;
}

@media screen and (max-width: 1400px) and (min-width: 1300px) {
  .my-container {
    max-width: 1299px;
    margin: auto;
  }
}

@media screen and (max-width: 1299px) and (min-width: 1200px) {
  .my-container {
    max-width: 1199px;
    margin: auto;
  }
  .backend-image-slider-container {
    width: 420px;
    overflow: hidden;
  }
  .profile_circle_main {
    width: 420px;
    height: 88px;
  }
}

@media screen and (max-width: 1199px) and (min-width: 1150px) {
  .my-container {
    max-width: 1199px;
    margin: auto;
  }
  .backend-image-slider-container {
    width: 386px;
    overflow: hidden;
  }
  .profile_circle_main {
    width: 386px;
    height: 88px;
  }
}

@media screen and (max-width: 1149px) and (min-width: 1100px) {
  .my-container {
    max-width: 1199px;
    margin: auto;
  }
  .backend-image-slider-container {
    width: 356px;
    overflow: hidden;
  }
  .profile_circle_main {
    width: 356px;
    height: 88px;
  }
}

@media (max-width: 1099px) {
}

@media only screen and (max-width: 800px) {
  .profile__details {
    flex-direction: column;
    align-items: center;
  }
  .profile__details > div {
    width: 80%;
  }
  .profile__counts {
    align-items: center;
    justify-content: center;
  }
  .profile__details > img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (max-width: 800px) {
  .profile__details > div {
    width: 96%;
  }
}

@media only screen and (max-width: 1024px) {
  .edit_pencil {
    display: grid;
    place-items: center;
    margin-top: 32px;
  }
}

@media only screen and (min-width: 1440px) {
  .edit_pencil {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
  }
}

@media only screen and (min-width: 425px) {
  .test {
    margin-top: 30px;
  }
}

.editIcon:hover {
  color: red;
  cursor: pointer;
}

@media screen and (max-width: 678px) and (min-width: 576px) {
  .backend-image-slider-container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .slide_media_width {
    width: 47.5%;
    height: 50px;
  }
  .media_for_height_slider {
    height: 80px;
  }
  .profile_circle_main {
    width: 260px;
    height: 65px;
    padding-left: 12px;
    padding-top: 4px;
    display: grid;
    grid-template-columns: 0.31fr 1fr;
  }
  .complete_profile_btn h6:last-child {
    width: 120px;
    font-size: 14px;
  }
  .backend-image-slider {
    height: 140px;
    width: 100%;
    border-radius: 10px;
  }
  .backend-image-slider-container-main {
    width: 100%;
  }

  .backend-image-slider-container {
    width: 100%;
  }
  .complete_profile_btn h6 {
    margin-left: 8px;
  }
  .complete_profile_btn h6:first-child {
    display: none;
  }
}

@media (max-width: 500px) {
  .my-container {
    max-width: 1199px;
    margin: auto;
  }
  .backend-image-slider-container-main {
    width: 100% !important;
  }
  .backend-image-slider-container {
    width: 100%;
  }
  .profile_circle_main {
    width: 100%;
    height: 68px;
  }
  .slider-main-width {
    width: 100%;
  }
}
