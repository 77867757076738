.package_border_detail {
    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.25), 2px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    /* border: 1px solid lightgray; */
}

.package_contact_button {
    gap: 20px;
}

.package_contact_button button {
    background: linear-gradient( 180deg, rgba(207, 22, 111, 0.5) 0%, rgba(253, 7, 7, 0.5) 100%);
    border-radius: 10px;
    height: 35px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: white;
    width: 100px;
}