.vendor-inquiries-title {
  display: flex;
  justify-content: space-between;
}

.vendor-inquiries-title h5 {
  font-weight: 600;
  font-size: 24px;
}

.vendor-inquiries-title button {
  border: 1px solid gray;
  background-color: transparent;
  padding: 3px 11px;
  border-radius: 50%;
  position: relative;
}

.filter-icon-inquiry {
  font-size: 14px;
  color: gray;
}

.inquiriescard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

/* dknsdd */

.filter-icon-inquiry {
  cursor: pointer;
}

.dropdown-inquiry {
  position: absolute;
  top: 67%;
  right: 11%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.dropdown-inquiry button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-inquiry button:hover {
  background-color: #f2f2f2;
}
